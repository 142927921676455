import { Font, StyleSheet } from "@react-pdf/renderer";
import Button from "components/button";
import Input from "components/input";
import Loading from "components/loading";
import global from "global";
import React from "react";
import { useParams } from "react-router-dom";
import request from "request";
import { useDispatch, useSelector } from "store/hooks";
import prettierNumber from "utils/prettier-number";
import transcript from "utils/transcript";
import styles from "./scoring.module.scss";
import ResultBox from "components/result-box";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
      fontWeight: "medium",
    },
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf",
      fontWeight: "bold",
    },
  ],
});

export const pdfStyles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    padding: "5px 10px",
    fontWeight: "medium",
  },
  section: {
    marginBottom: "10px",
    marginTop: "10px",
  },
  heading: {
    fontSize: "24px",
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  heading2: {
    fontSize: "20px",
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  text: {
    fontSize: "14px",
    marginBottom: "10px",
  },
  table: {
    backgroundColor: "#fff",
  },
  tr: {
    display: "flex",
    flexDirection: "row",
  },
  td: {
    border: "1px solid #000",
    width: "100%",
    padding: "5px 10px",
    fontSize: "14px",
  },
  th: {
    border: "1px solid #000",
    width: "100%",
    padding: "5px 10px",
    fontSize: "14px",
    fontWeight: "bold",
  },
  result: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  bottom: {
    margin: "10px 0 0 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

const initialFormState = {
  age: "",
  gender: "",
  marital_status: "",
  credit_history_count: "",
  district: "",
  sector: "",
  education: "",
  is_disaster: "",
  phone_type: "",
  has_transport: "",
  has_house: "",
  work_place: "",
  work_experience: "",
  monthly_income: "",
  bkit_score: "",
  job_status: "",
  dependants: "",
  co_borrower: "",
  has_collateral: "",
};

const Scoring = () => {
  const { id } = useParams();
  const contextApplication = useSelector((state) => state.application);
  const dispatch = useDispatch();

  const [pending, setPending] = React.useState(false);
  const [validationCheck, setValidationCheck] = React.useState(false);

  const [macrodata, setMacrodata] = React.useState<global.macrodata>();
  const [form, setForm] = React.useState(initialFormState);

  const handleCalculate = () => {
    let timeout: NodeJS.Timeout;
    setPending(true);
    request
      .post(`/applications/${id}/scoring`, {
        age: Number(form.age),
        gender: transcript.gender(form.gender),
        marital_status: transcript.marital_status(form.marital_status),
        credit_history_count: Number(form.credit_history_count),
        district: transcript.district(form.district),
        sector: transcript.sector(form.sector),
        education: transcript.education(form.education),
        is_disaster: transcript.is_disaster(form.is_disaster),
        phone_type: transcript.phone_type(form.phone_type),
        has_transport: transcript.has_transport(form.has_transport),
        has_house: transcript.has_house(form.has_house),
        work_place: transcript.work_place(form.work_place),
        work_experience: Number(form.work_experience),
        monthly_income: Number(form.monthly_income),
        bkit_score: transcript.bkit_score(form.bkit_score),
        job_status: transcript.job_status(form.job_status),
        dependants: Number(form.dependants),
        co_borrower: transcript.co_borrower(form.co_borrower),
        has_collateral: transcript.has_collateral(form.has_collateral),
        rub_exchange: macrodata?.rub_exchange,
        food_inflation: macrodata?.food_inflation,
        nonfood_inflation: macrodata?.nonfood_inflation,
        services_inflation: macrodata?.services_inflation,
      })
      .then((res) => res.data)
      .then(() => {
        timeout = setTimeout(
          () => dispatch.application.FETCH_APPLICATION_BY_ID(id),
          1000
        );
      })
      .finally(() => setPending(false));
    return () => clearTimeout(timeout);
  };

  React.useEffect(() => {
    request
      .get("/admin/constant_values")
      .then((res) => res.data)
      .then((d) => setMacrodata(d));
  }, []);

  React.useEffect(() => {
    setValidationCheck(
      form.age !== "" &&
        form.gender !== "" &&
        form.marital_status !== "" &&
        form.credit_history_count !== "" &&
        form.district !== "" &&
        form.sector !== "" &&
        form.education !== "" &&
        form.is_disaster !== "" &&
        form.phone_type !== "" &&
        form.has_transport !== "" &&
        form.has_house !== "" &&
        form.work_place !== "" &&
        form.work_experience !== "" &&
        form.monthly_income !== "" &&
        form.bkit_score !== "" &&
        form.job_status !== "" &&
        form.dependants !== "" &&
        form.co_borrower !== "" &&
        form.has_collateral !== ""
    );
  }, [form]);

  return (
    <form className={styles.home}>
      <div className={styles.fields}>
        <div className={styles.creditDataBox}>
          <div className={styles.title}>Данные кредита</div>
          <div className={styles.list}>
            <div className={styles.item}>
              <div className={styles.key}>Сумма</div>
              <div className={styles.value}>
                {prettierNumber(Number(contextApplication.data?.amount))} TJS
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Длительность</div>
              <div className={styles.value}>
                {contextApplication.data?.duration} мес.
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Процентная ставка</div>
              <div className={styles.value}>
                {(Number(contextApplication.data?.int_rate) * 100).toFixed(1)} %
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Порог</div>
              <div className={styles.value}>
                {contextApplication.data?.scoring_log?.threshold
                  ? (
                      contextApplication.data?.scoring_log?.threshold * 100
                    ).toFixed()
                  : ((macrodata?.threshold ?? 0) * 100).toFixed()}{" "}
                %
              </div>
            </div>
            {contextApplication.data?.scoring_log && (
              <>
                <div className={styles.item}>
                  <div className={styles.key}>
                    Количество исторических кредитов
                  </div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.credit_history_count}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Цель кредита</div>
                  <div className={styles.value}>
                    {transcript.sector(
                      contextApplication.data.scoring_log.sector
                    )}
                  </div>
                </div>

                <div className={styles.item}>
                  <div className={styles.key}>
                    Кредит во время экстренного периода
                  </div>
                  <div className={styles.value}>
                    {transcript.is_disaster(
                      contextApplication.data.scoring_log?.is_disaster
                    )}
                  </div>
                </div>

                <div className={styles.item}>
                  <div className={styles.key}>Залог</div>
                  <div className={styles.value}>
                    {transcript.has_collateral(
                      contextApplication.data.scoring_log?.has_collateral
                    )}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Поручитель/Созаемщик</div>
                  <div className={styles.value}>
                    {transcript.co_borrower(
                      contextApplication.data.scoring_log?.co_borrower
                    )}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Оценка БКИТ</div>
                  <div className={styles.value}>
                    {transcript.bkit_score(
                      contextApplication.data.scoring_log?.bkit_score
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className={styles.creditDataBox}>
          <div className={styles.title}>Макроданные</div>
          <div className={styles.list}>
            <div className={styles.item}>
              <div className={styles.key}>
                Инфляция на продовольственные товары
              </div>
              <div className={styles.value}>{macrodata?.food_inflation}</div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>
                Инфляция непотребительских товаров
              </div>
              <div className={styles.value}>{macrodata?.nonfood_inflation}</div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Инфляция услуг</div>
              <div className={styles.value}>
                {contextApplication.data?.scoring_log?.services_inflation ??
                  macrodata?.services_inflation}
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Курс рубля</div>
              <div className={styles.value}>{macrodata?.rub_exchange}</div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Курс доллара</div>
              <div className={styles.value}>{macrodata?.exrate_usd_avg}</div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Средний доход населения</div>
              <div className={styles.value}>
                {contextApplication.data?.scoring_log?.avrate ??
                  macrodata?.avrate}
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>ВВП</div>
              <div className={styles.value}>
                {contextApplication.data?.scoring_log?.gdp ?? macrodata?.gdp}
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Цена на нефть марки Брент</div>
              <div className={styles.value}>
                {contextApplication.data?.scoring_log?.brent_oil_price ??
                  macrodata?.brent_oil_price}
              </div>
            </div>
          </div>
        </div>

        {contextApplication.data?.scoring_log && (
          <div className={styles.creditDataBox}>
            <div className={styles.title}>Данные клиента</div>
            <div className={styles.list}>
              <div className={styles.item}>
                <div className={styles.key}>Пол</div>
                <div className={styles.value}>
                  {transcript.gender(
                    contextApplication.data.scoring_log.gender
                  )}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>Возраст</div>
                <div className={styles.value}>
                  {contextApplication.data.scoring_log.age}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>Семейное положение</div>
                <div className={styles.value}>
                  {transcript.marital_status(
                    contextApplication.data.scoring_log.marital_status
                  )}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>Образование</div>
                <div className={styles.value}>
                  {transcript.education(
                    contextApplication.data.scoring_log.education
                  )}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>Город/Район</div>
                <div className={styles.value}>
                  {transcript.district(
                    contextApplication.data.scoring_log.district
                  )}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>Сфера занятости</div>
                <div className={styles.value}>
                  {transcript.work_place(
                    contextApplication.data.scoring_log.work_place
                  )}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>Ежемесячный доход</div>
                <div className={styles.value}>
                  {contextApplication.data.scoring_log.monthly_income}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>Статус деятельности</div>
                <div className={styles.value}>
                  {transcript.job_status(
                    contextApplication.data.scoring_log.job_status
                  )}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>Количество иждивиенцев</div>
                <div className={styles.value}>
                  {contextApplication.data.scoring_log.dependants}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>Опыт работы в годах</div>
                <div className={styles.value}>
                  {contextApplication.data.scoring_log?.work_experience}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>Наличие телефона</div>
                <div className={styles.value}>
                  {transcript.phone_type(
                    contextApplication.data.scoring_log.phone_type
                  )}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>Наличие автомобиля</div>
                <div className={styles.value}>
                  {transcript.has_transport(
                    contextApplication.data.scoring_log.has_transport
                  )}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>Наличие жилья</div>
                <div className={styles.value}>
                  {transcript.has_house(
                    contextApplication.data.scoring_log.has_house
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {!contextApplication.data?.scoring_log && (
          <>
            <div className={styles.fieldGroup}>
              <div className={styles.title}>Данные кредита</div>
              <div className={styles.fieldList}>
                <Input
                  title="Количество исторических кредитов"
                  value={form.credit_history_count}
                  type="integer"
                  step={1}
                  min={0}
                  placeholder={`мин: 0`}
                  onInput={(value) =>
                    setForm({ ...form, credit_history_count: value })
                  }
                />
                <Input
                  title="Цель кредита"
                  id="sector"
                  value={form.sector}
                  type="select"
                  onSelect={(option) => setForm({ ...form, sector: option })}
                  options={[
                    "Услуги",
                    "Сельское хозяйство",
                    "Торговля",
                    "Производство",
                    "Потребительский",
                    "Индивидуальный предприниматель",
                    "Ипотека",
                  ]}
                />
                <Input
                  title="Кредит во время экстренного периода"
                  id="lendingType"
                  value={form.is_disaster}
                  type="select"
                  onSelect={(option) =>
                    setForm({ ...form, is_disaster: option })
                  }
                  options={["Да", "Нет"]}
                />
                <Input
                  title="Залог"
                  id="has_collateral"
                  type="select"
                  value={form.has_collateral}
                  onSelect={(option) =>
                    setForm({ ...form, has_collateral: option })
                  }
                  options={["Да", "Нет"]}
                />

                <Input
                  title="Поручитель/Созаемщик"
                  id="has_guarantor"
                  type="select"
                  value={form.co_borrower}
                  onSelect={(option) =>
                    setForm({ ...form, co_borrower: option })
                  }
                  options={["Да", "Нет"]}
                />
                <Input
                  title="Оценка БКИТ"
                  id="bkit_score"
                  value={form.bkit_score}
                  type="select"
                  onSelect={(option) =>
                    setForm({ ...form, bkit_score: option })
                  }
                  options={[
                    "A",
                    "B",
                    "C",
                    "D",
                    "E",
                    "F",
                    "G",
                    "H",
                    "I",
                    "J",
                    "M",
                    "N",
                    "O",
                    "P",
                    "Q",
                    "R",
                    "S",
                    "T",
                    "U",
                    "V",
                    "W",
                    "X",
                    "Y",
                    "Z",
                    "Нет оценки",
                  ]}
                />
              </div>
            </div>

            <div className={styles.fieldGroup}>
              <div className={styles.title}>Данные клиента</div>
              <div className={styles.fieldList}>
                <Input
                  title="Возраст"
                  value={form.age}
                  type="integer"
                  step={1}
                  min={18}
                  max={63}
                  placeholder={`мин: 18  макс: 63`}
                  onInput={(value) => setForm({ ...form, age: value })}
                />
                <Input
                  title="Пол"
                  id="gender"
                  value={form.gender}
                  type="select"
                  onSelect={(option) => setForm({ ...form, gender: option })}
                  options={["Мужской", "Женский"]}
                />

                <Input
                  title="Город/Район"
                  hasFilterText
                  id="district"
                  value={form.district}
                  type="select"
                  onSelect={(option) => setForm({ ...form, district: option })}
                  options={[
                    "Матчинский район",
                    "Гиссарский район",
                    "Душанбе",
                    "Гафуровский район",
                    "Рудаки",
                    "Балхи",
                    "Джаббар-Расуловский район",
                    "Худжанд",
                    "Шахритуз",
                    "Исфара",
                    "Куляб",
                    "Истаравшан",
                    "Вахдат",
                    "Спитамен",
                    // "Конибодом",
                    "Бохтар",
                    "Зафаробод",
                    "Ашт",
                    "Джайхун",
                    "Шахристон",
                    "Ёвон",
                    "Дангара",
                    "Пенджикент",
                    "Турсунзода",
                    "Деваштич",
                    // "Кушониён",
                    "Муминобод",
                    "Хамадони",
                    "Восеъ",
                    "Кубодян",
                    "Сомон",
                    "Фархор",
                    "Дусти",
                    "Темурмалик",
                    "Вахш",
                    "Пяндж",
                    "Хуросон",
                    "Шахринав",
                    "Джоми",
                    "Сангтуда",
                    "Кангурт",
                    "Носири Хусрав",
                    "Файзобод",
                    "Фаровон",
                    // "Чирик",
                    // "Гулистон",
                    "Бустон",
                    //"Леваканд (Сарбанд)",
                    // "Хорог",
                    //"Табошар",
                    "Нурек",
                    //"Фирдавси",
                    //"Курган",
                    // "Эшбек Саторов",
                    "Корвон",
                  ].sort()}
                />
                <Input
                  title="Семейное положение"
                  id="marital_status"
                  value={form.marital_status}
                  type="select"
                  onSelect={(option) =>
                    setForm({ ...form, marital_status: option })
                  }
                  options={[
                    "Женат/Замужем",
                    "Разведён/Разведена",
                    "Холостой",
                    "Вдовец/Вдова",
                  ]}
                />
                <Input
                  title="Образование"
                  id="education"
                  value={form.education}
                  type="select"
                  onSelect={(option) => setForm({ ...form, education: option })}
                  options={[
                    "Высшее образование",
                    "Неполное среднее образование",
                    "Среднее образование",
                    "Среднее образование со специализацией",
                    "Другое образование",
                  ]}
                />
                <Input
                  title="Ежемесячный доход"
                  value={form.monthly_income}
                  type="integer"
                  step={1}
                  min={500}
                  placeholder={`мин: 500`}
                  onInput={(value) =>
                    setForm({ ...form, monthly_income: value })
                  }
                />

                <Input
                  title="Кол-во иждивенцев"
                  value={form.dependants}
                  type="integer"
                  step={1}
                  min={0}
                  max={30}
                  placeholder={`мин: 0  макс: 30`}
                  onInput={(value) => setForm({ ...form, dependants: value })}
                />
                <Input
                  title="Сфера занятости"
                  id="work_place"
                  value={form.work_place}
                  type="select"
                  onSelect={(option) =>
                    setForm({ ...form, work_place: option })
                  }
                  options={[
                    "Государственное учреждение",
                    "Частная компания",
                    "Общественная организация",
                    "Индивидуальный предприниматель",
                    "Другое",
                    "Нет",
                  ]}
                />
                <Input
                  title="Статус деятельности"
                  id="job_status"
                  value={form.job_status}
                  type="select"
                  onSelect={(option) =>
                    setForm({ ...form, job_status: option })
                  }
                  options={["Трудоустроен", "Безработный", "Другое"]}
                />

                <Input
                  title="Опыт работы в годах"
                  value={form.work_experience}
                  type="integer"
                  step={1}
                  min={1}
                  max={50}
                  placeholder={`мин: 1  макс: 50`}
                  onInput={(value) =>
                    setForm({ ...form, work_experience: value })
                  }
                />
                <Input
                  title="Наличие жилья"
                  id="has_house"
                  value={form.has_house}
                  type="select"
                  onSelect={(option) => setForm({ ...form, has_house: option })}
                  options={[
                    "Аренда",
                    "Владение",
                    "В кредит",
                    "Другое",
                    "Нет жилья",
                  ]}
                />
                <Input
                  title="Наличие телефона"
                  id="phone_type"
                  value={form.phone_type}
                  type="select"
                  onSelect={(option) =>
                    setForm({ ...form, phone_type: option })
                  }
                  options={["IOS", "Android", "Другое", "Нет телефона"]}
                />

                <Input
                  title="Наличие транспорта"
                  id="has_transport"
                  value={form.has_transport}
                  type="select"
                  onSelect={(option) =>
                    setForm({ ...form, has_transport: option })
                  }
                  options={[
                    "Аренда",
                    "Владение",
                    "В кредит",
                    "Другое",
                    "Нет транспорта",
                  ]}
                />
              </div>
            </div>
            {validationCheck && (
              <div className={styles.footerBox}>
                <div className={styles.buttonGroup}>
                  <Button pending={pending} onClick={handleCalculate}>
                    Запустить скоринг
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {(pending || contextApplication.data?.scoring_log) && (
        <div className={styles.result}>
          {pending && (
            <div className={styles.resultBox}>
              <Loading size={50} />
            </div>
          )}
          <div className={styles.resultBoxGroup}>
            <ResultBox />
          </div>
        </div>
      )}
    </form>
  );
};

export default Scoring;
