import React from "react";

import Input from "components/input";
import Button from "components/button";
import { useSelector } from "store/hooks";
import request from "request";
import styles from "./create-modal.module.scss";
import transcript from "utils/transcript";

const CreditUserModal = ({
  onClose,
  onCreate,
}: {
  onClose: () => void;
  onCreate: () => void;
}) => {
  const user = useSelector((state) => state.user);

  const [email, setLogin] = React.useState("");
  const [firstname, setFirstname] = React.useState("");
  const [surname, setSurname] = React.useState("");
  const [password1, setPassword1] = React.useState("");
  const [password2, setPassword2] = React.useState("");
  const [role, setRole] = React.useState("Кредитный эксперт");
  const [pending, setPending] = React.useState(false);
  const [validError, setValidError] = React.useState<{
    name: "firstname" | "surname" | "email" | "password1" | "password2" | "role";
    value: string;
  } | null>(null);

  const handleCreate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (firstname.trim() === "")
      return setValidError({
        name: "firstname",
        value: "Введите имя",
      });
    if (surname.trim() === "")
      return setValidError({
        name: "surname",
        value: "Введите фамилию",
      });
    if (email.trim() === "")
      return setValidError({
        name: "email",
        value: "Введите почту",
      });
    if (password1.trim().length < 6)
      return setValidError({
        name: "password1",
        value: "Минимальная длина пароля 6 символов",
      });
    if (password1.trim() !== password2.trim())
      return setValidError({
        name: "password2",
        value: "Пароли не совпадают",
      });
    if (user.role === "root" && role === "")
      return setValidError({
        name: "role",
        value: "Роль обязателена",
      });
    setValidError(null);
    setPending(true);
    request
      .post("/admin/users", {
        first_name: firstname,
        last_name: surname,
        email,
        password1,
        password2,
        role: transcript.userRole.getKeyFromValue(role),
      })
      .then(() => onCreate())
      .finally(() => setPending(false));
  };

  return (
    <div className={styles.modal} onMouseDown={onClose}>
      <form
        className={styles.modalWrap}
        onSubmit={handleCreate}
        onMouseDown={(event) => event.stopPropagation()}
      >
        <div className={styles.title}>Новый пользователь</div>
        <div className={styles.inputs}>
          <div className={styles.input}>
            <Input
              title="Имя"
              type="string"
              value={firstname}
              error={validError?.name === "firstname" ? validError.value : undefined}
              onInput={(value) => setFirstname(value)}
            />
          </div>
          <div className={styles.input}>
            <Input
              title="Фамилия"
              type="string"
              value={surname}
              error={validError?.name === "surname" ? validError.value : undefined}
              onInput={(value) => setSurname(value)}
            />
          </div>
          <div className={styles.input}>
            <Input
              title="Эл. почта"
              type="string"
              value={email}
              error={validError?.name === "email" ? validError.value : undefined}
              onInput={(value) => setLogin(value)}
            />
          </div>
          <div className={styles.input}>
            <Input
              title="Пароль"
              type="password"
              value={password1}
              error={validError?.name === "password1" ? validError.value : undefined}
              onInput={(value) => setPassword1(value)}
            />
          </div>
          <div className={styles.input}>
            <Input
              title="Подтвердить пароль"
              type="password"
              value={password2}
              error={validError?.name === "password2" ? validError.value : undefined}
              onInput={(value) => setPassword2(value)}
            />
          </div>
          {user.role === "root" && (
            <div className={styles.input}>
              <Input
                id="role"
                title="Роль"
                type="select"
                value={role}
                onSelect={(option) => setRole(option)}
                error={validError?.name === "role" ? validError.value : undefined}
                options={["Кредитный эксперт", "Администратор"]}
              />
            </div>
          )}
        </div>
        <div className={styles.buttonGroup}>
          <Button color="orange" onClick={onClose}>
            Отмена
          </Button>
          <Button type="submit" color="green" pending={pending}>
            Создать
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CreditUserModal;