import React from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { toast } from "react-toastify";

import request from "request";
import Input from "components/input";
import styles from "./login.module.scss";
import ButtonLoader from "components/button-loader";

const Login = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [isPendingLogin, setIsPendingLogin] = React.useState(false);
  const [secondsBeforeNewCodeSent, setSecondsBeforeNewCodeSent] =
    React.useState(0);

  const navigate = useNavigate();

  const correctFields = () => {
    const regexCheckEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email.trim() === "") {
      toast.error("Пожалуйста, заполните поле электронной почты");
      return false;
    }
    if (!email.toLowerCase().match(regexCheckEmail)) {
      toast.error("Пожалуйста, заполните поле электронной почты правильно");
      return false;
    }
    if (password.trim() === "") {
      toast.error("Пожалуйста, заполните поле пароля");
      return false;
    }
    return true;
  };

  const handlLogin = (event: React.FormEvent) => {
    event.preventDefault();
    if (isPendingLogin || secondsBeforeNewCodeSent > 0 || !correctFields())
      return;

    setIsPendingLogin(true);

    request
      .post("/login", {
        email: email,
        password: password,
      })
      .then((res) => res.data)
      .then((data) => {
        setIsPendingLogin(false);
        toast.success("Успешный вход в систему");
        localStorage.setItem("token", data.token);
        navigate("/");
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 401) toast.error("Неверный пароль!");
        if (err.response?.status === 404)
          toast.error("Пользователь не найден!");
        else if (err.response?.status === 429)
          toast.error("Подождите перед повторной отправкой кода");
      })
      .finally(() => {
        setIsPendingLogin(false);
      });
  };

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) navigate("/");
    else localStorage.clear();
  }, [navigate]);

  React.useEffect(() => {
    if (secondsBeforeNewCodeSent > 0) {
      let timeout = setTimeout(
        () => setSecondsBeforeNewCodeSent((prev) => prev - 1),
        1000
      );
      return () => clearTimeout(timeout);
    }
  }, [secondsBeforeNewCodeSent]);

  return (
    <div className={classNames(styles.email, isPendingLogin && styles.pending)}>
    <div className={styles.container}>
      {
        <form className={styles.pan} onSubmit={handlLogin}>
          <div className={styles.inputs}>
            <Input
              title="Эл. почта"
              type="string"
              value={email}
              onInput={(value) => setEmail(value)}
            />
            <Input
              title="Пароль"
              type="password"
              value={password}
              onInput={(value) => setPassword(value)}
            />
          </div>
          <button className={styles.submit}>
            {isPendingLogin && (
              <span className={styles.loading}>
                <ButtonLoader />
              </span>
            )}
            {!isPendingLogin && (
              <span className={styles.submitText}>Войти</span>
            )}
          </button>
        </form>
      }
    </div>
  </div>
  );
};

export default Login;
