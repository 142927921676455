const prettierNumber = (num: number): string => {
  let textNum = String(num);
  let result = "";

  while (true) {
    for (let i = 0; i < 3 && textNum !== ""; i++) {
      result = textNum[textNum.length - 1] + result;
      textNum = textNum.slice(0, textNum.length - 1);
    }
    if (textNum === "") break;
    result = " " + result;
  }

  return result;
};

export default prettierNumber;
