import isDev from "./is-dev";
import isTestDeploy from "./is-test-deploy";

const testURL = "https://brt.westeurope.cloudapp.azure.com/dev";

const prodURL = "https://brt.westeurope.cloudapp.azure.com/prod";

// const baseURL = isDev ? devURL : prodURL;
const baseURL = isTestDeploy || isDev ? testURL : prodURL;

export default baseURL;
