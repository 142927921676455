import React from "react";
import { CSSTransition } from "react-transition-group";
import Icon from "icons";
import classNames from "classnames";
import { useSelector } from "store/hooks";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Pdf } from "../../pages/result";
import Button from "components/button";
import prettierNumber from "utils/prettier-number";
import styles from "./result-box.module.scss";
import modalStyles from "./modals/recommendation.module.scss";
import Modal from "./modals/recommendation";
import Result from "pages/result";

const ResultBox = () => {
  const contextApplication = useSelector((state) => state.application);

  const [recommendationModal, setRecommendationModal] = React.useState(false);

  const handleOpenRecommendation = () => setRecommendationModal(true);

  const selectedRecommendation = React.useMemo(() => {
    return contextApplication.data?.recommendations_log?.find(
      (rec) => rec.id === contextApplication.data?.selected_recommendation
    );
  }, [
    contextApplication.data?.selected_recommendation,
    contextApplication.data?.recommendations_log,
  ]);

  return (
    <div className={styles.resultList}>
      {contextApplication.data?.selected_recommendation && (
        <div className={styles.recommendationBox}>
          <div className={styles.recommendationBox_title}>
            Выбранная рекомендация
          </div>
          <div className={styles.item}>
            <div className={styles.key}>Сумма</div>
            <div className={styles.key}>
              {prettierNumber(selectedRecommendation?.amount ?? 0)} TJS
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.key}>Длительность</div>
            <div className={styles.key}>
              {selectedRecommendation?.duration} мес.
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.key}>Вероятность выплаты</div>
            <div className={styles.key}>
              {Number(selectedRecommendation?.prob).toFixed()} %
            </div>
          </div>
        </div>
      )}
      <div className={styles.resultBox}>
        <div className={styles.resultBox_title}>Статус заявки</div>
        {contextApplication.data?.is_approved !== null && (
          <>
            <div className={styles.finalResult}>
              <Icon
                size={120}
                name={
                  contextApplication.data?.is_approved
                    ? "tickCircle"
                    : "closeFilled"
                }
                className={classNames(
                  contextApplication.data?.is_approved
                    ? styles.green
                    : styles.red
                )}
              />
              <div
                className={classNames(
                  styles.finalResultText,
                  contextApplication.data?.is_approved
                    ? styles.green
                    : styles.red
                )}
              >
                {contextApplication.data?.selected_recommendation
                  ? "Одобрено по рекомендации"
                  : contextApplication.data?.is_approved
                  ? "Одобрено"
                  : "Отказано"}{" "}
                {selectedRecommendation
                  ? Math.round(selectedRecommendation?.prob)
                  : (
                      Number(contextApplication.data?.scoring_log?.prediction) *
                      100
                    ).toFixed()}
                %
              </div>
            </div>
            <PDFDownloadLink
              fileName={"кредитная-заявка-" + contextApplication.data?.id}
              document={<Pdf contextApplication={contextApplication} />}
            >
              <Button color="blue">Скачать результат</Button>
            </PDFDownloadLink>
          </>
        )}
        {contextApplication?.data &&
        !contextApplication?.data?.selected_recommendation &&
        contextApplication?.data.recommendations_log?.length > 0 ? (
          <Button color="violet" onClick={handleOpenRecommendation}>
            Рекомендации к заявке
          </Button>
        ) : contextApplication.data?.is_approved === false ? (
          <div className={styles.non_rec_text}>Рекомендаций нет</div>
        ) : (
          <></>
        )}
      </div>
      <CSSTransition
        in={!!recommendationModal}
        timeout={150}
        unmountOnExit
        classNames={{
          enter: modalStyles.modalEnter,
          enterActive: modalStyles.modalEnterActive,
          exit: modalStyles.modalExit,
          exitActive: modalStyles.modalExitActive,
        }}
      >
        <Modal
          application={contextApplication.data}
          onClose={() => setRecommendationModal(false)}
        />
      </CSSTransition>
    </div>
  );
};

export default ResultBox;
