import React from "react";
import classNames from "classnames";

import loading from "./loading";
import styles from "./loading.module.scss";

const Loading = ({ size = 50, light = false }: loading.props) => {
  return (
    <div
      className={classNames(styles.ldsDualRing, light && styles.light)}
      style={{ width: size + "px", height: size + "px" }}
    ></div>
  );
};

export default Loading;
