import React from "react";
import { AxiosError } from "axios";
import { useAlert } from "react-alert";

import Button from "components/button";
import RangeBox from "components/range-box";
import request from "request";
import styles from "./settings.module.scss";
import Input from "components/input";

const Settings = () => {
  const alert = useAlert();
  const [updatePending, setUpdatePending] = React.useState(false);
  const [loadPending, setLoadPending] = React.useState(true);
  const [range, setRange] = React.useState(20);
  const [foodInflation, setFoodInflation] = React.useState("");
  const [nonfoodInflation, setNonfoodInflation] = React.useState("");
  const [servicesInflation, setServicesInflation] = React.useState("");
  const [exrateUsdAvg, setExrateUsdAvg] = React.useState("");
  const [rubExchange, setRubExchange] = React.useState("");
  const [avrate, setAvrate] = React.useState("");
  const [gdp, setGdp] = React.useState("");
  const [brentOilPrice, setBrentOilPrice] = React.useState("");

  const handleSetSettings = () => {
    setUpdatePending(true);
    request
      .put("/admin/constant_values", {
        threshold: range / 100,
        exrate_usd_avg: exrateUsdAvg,
        avrate: avrate,
        gdp: gdp,
        brent_oil_price: brentOilPrice,
        rub_exchange: rubExchange,
        food_inflation: foodInflation,
        nonfood_inflation: nonfoodInflation,
        services_inflation: servicesInflation,
      })
      .then(() => alert.success("Сохранено!"))
      .catch((err: AxiosError) => {
        alert.show((err.response?.data as any).detail);
      })
      .finally(() => setUpdatePending(false));
  };

  React.useEffect(() => {
    setLoadPending(true);
    request
      .get("/admin/constant_values")
      .then((res) => res.data)
      .then((data) => {
        setRange(Math.round(data.threshold * 100));
        setExrateUsdAvg(data.exrate_usd_avg);
        setAvrate(data.avrate);
        setGdp(data.gdp);
        setBrentOilPrice(data.brent_oil_price);
        setRubExchange(data.rub_exchange);
        setFoodInflation(data.food_inflation);
        setNonfoodInflation(data.nonfood_inflation);
        setServicesInflation(data.services_inflation);
      })
      .finally(() => setLoadPending(false));
  }, []);

  if (loadPending) return <></>;
  return (
    <form className={styles.settings}>
      <div className={styles.fields}>
        <div className={styles.fieldGroup}>
          <div className={styles.fieldTitle}>Настройки</div>
          <div className={styles.fieldList}>
            <Input
              title="Инфляция на продовольственные товары"
              value={foodInflation}
              step={0.1}
              type="float"
              onInput={(value) => setFoodInflation(value)}
            />
            <Input
              title="Инфляция непотребительских товаров"
              value={nonfoodInflation}
              step={0.1}
              type="float"
              onInput={(value) => setNonfoodInflation(value)}
            />
            <Input
              title="Инфляция услуг"
              value={servicesInflation}
              step={0.1}
              type="float"
              onInput={(value) => setServicesInflation(value)}
            />
            <Input
              title="Курс доллара"
              value={exrateUsdAvg}
              step={0.1}
              type="float"
              onInput={(value) => setExrateUsdAvg(value)}
            />
            <Input
              title="Курс рубля"
              value={rubExchange}
              step={0.1}
              type="float"
              onInput={(value) => setRubExchange(value)}
            />
            <Input
              title="Средний доход населения"
              value={avrate}
              step={0.1}
              type="float"
              onInput={(value) => setAvrate(value)}
            />
            <Input
              title="ВВП"
              value={gdp}
              step={0.1}
              type="float"
              onInput={(value) => setGdp(value)}
            />
            <Input
              title="Цена на нефть марки Брент"
              value={brentOilPrice}
              step={0.1}
              type="float"
              onInput={(value) => setBrentOilPrice(value)}
            />
          </div>
          <div className={styles.fieldGroup}>
            <div className={styles.fieldTitle}>Порог</div>
            <RangeBox value={range} setValue={setRange} />
          </div>
          <div className={styles.footerBox}>
            <Button pending={updatePending} onClick={handleSetSettings}>
              Сохранить
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Settings;
