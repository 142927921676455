import React from "react";
import global from "global";
import classNames from "classnames";
import { toast } from "react-toastify";

import prettierNumber from "utils/prettier-number";
import { useDispatch } from "store/hooks";
import Button from "components/button";
import request from "request";
import styles from "./recommendation.module.scss";

const Modal = ({
  onClose,
  application,
}: {
  onClose: () => void;
  application: global.application | null;
}) => {
  const dispatch = useDispatch();
  const [selectedRecommendation, setSelectedRecommendation] =
    React.useState<global.recommendation>();
  const [pending, setPending] = React.useState(false);

  const handleSelectRecommendation = (
    recommendation: global.recommendation
  ) => {
    setSelectedRecommendation(recommendation);
  };

  const handleSubmit = () => {
    if (!selectedRecommendation)
      toast.warning("Пожалуйста, выберите рекомендацию");
    setPending(true);
    request
      .post(
        `/applications/${application?.id}/recommendation/${selectedRecommendation?.id}`
      )
      .then(() => {
        dispatch.application.FETCH_APPLICATION_BY_ID(application?.id);
      })
      .finally(() => {
        setPending(false);
        onClose();
      });
  };

  return (
    <div className={styles.modal} onMouseDown={onClose}>
      <div
        className={styles.modalWrap}
        onMouseDown={(event) => event.stopPropagation()}
      >
        <div className={styles.title}>Рекомендации</div>
        <div className={styles.recommendations}>
          {application?.recommendations_log.map((recommendation, i) => (
            <button
              type="button"
              onClick={() => handleSelectRecommendation(recommendation)}
              className={classNames(
                styles.recommendation,
                selectedRecommendation?.id === recommendation.id &&
                  styles.selected
              )}
              key={recommendation.id}
            >
              <div className={styles.smallTitle}>Рекомендация {i + 1}</div>
              <div className={styles.item}>
                <div className={styles.key}>Сумма</div>
                <div className={styles.value}>
                  {prettierNumber(recommendation.amount)} TJS
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>Длительность</div>
                <div className={styles.value}>
                  {recommendation.duration} мес.
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>Вероятность выплаты</div>
                <div className={styles.value}>
                  {(recommendation.prob).toFixed()} %
                </div>
              </div>
            </button>
          ))}
        </div>
        <div className={styles.buttonGroup}>
          <Button pending={pending} onClick={handleSubmit}>
            Подтвердить
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Modal;