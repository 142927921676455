import React, { Suspense } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import request from "request";
import { useDispatch, useSelector } from "store/hooks";
import ApplicationLayout from "layouts/application";
import MainLayout from "layouts/main";
import Scoring from "pages/scoring";
import Home from "pages/home";
import Users from "pages/users";
import Login from "pages/login";
import Result from "pages/result";
import Settings from "pages/settings";
import ActionHistory from "pages/action-history";

const AppRoutes: React.FC = (): JSX.Element => {
  const location = useLocation();
  const [access, setAccess] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  React.useEffect(() => {
    const handleGetValidToken = async () => {
      const response = await request.get("validate_token");
      if (response.status === 200) {
        setAccess(true);
        dispatch.user.INIT(response.data);
      } else setAccess(false);
    };
    if (location.pathname !== "/login") handleGetValidToken();
    else setAccess(false);
  }, [dispatch, location.pathname, navigate]);

  return (
    <Suspense fallback="Loading">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={access ? <MainLayout /> : <></>}>
          <Route index element={<Home />} />
          <Route path="/application" element={<ApplicationLayout />}>
            {/* <Route path="/application/:id/ocr" element={<OCR />} /> */}
            <Route path="/application/:id/scoring" element={<Scoring />} />
            <Route path="/application/:id/result" element={<Result />} />
          </Route>
          <Route path="/settings" element={user.role !== "expert" ? <Settings /> : <></>} />
          <Route
            path="/action-history"
            element={user.role !== "expert" ? <ActionHistory /> : <></>}
          />
          <Route path="/users" element={user.role !== "expert" ? <Users /> : <></>} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
