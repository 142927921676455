const transcript = {
  job_status: (key: string) =>
    ({
      employed: "Трудоустроен",
      unemployed: "Безработный",
      other: "Другое",
      Трудоустроен: "employed",
      Безработный: "unemployed",
      Другое: "other",
    }[key]),
  phone_type: (key: string) =>
    ({
      ios: "IOS",
      android: "Android",
      no_phone: "Нет телефона",
      other: "Другое",
      IOS: "ios",
      Android: "android",
      "Нет телефона": "no_phone",
      Другое: "other",
    }[key]),
  sector: (key: string) =>
    ({
      services: "Услуги",
      Услуги: "services",
      consumer: "Потребительский",
      Потребительский: "consumer",
      trade: "Торговля",
      Торговля: "trade",
      agriculture: "Сельское хозяйство",
      "Сельское хозяйство": "agriculture",
      production: "Производство",
      Производство: "production",
      individual_entrepreneur: "Индивидуальный предприниматель",
      "Индивидуальный предприниматель": "individual_entrepreneur",
      mortgage: "Ипотека",
      Ипотека: "mortgage",
    }[key]),
  education: (key: string) =>
    ({
      higher_education: "Высшее образование",
      incomplete_secondary_education: "Неполное среднее образование",
      secondary_education: "Среднее образование",
      secondary_education_with_specialisation:
        "Среднее образование со специализацией",
      other: "Другое образование",
      "Другое образование": "other",
      "Высшее образование": "higher_education",
      "Неполное среднее образование": "incomplete_secondary_education",
      "Среднее образование": "secondary_education",
      "Среднее образование со специализацией":
        "secondary_education_with_specialisation",
    }[key]),
  is_disaster: (key: string) =>
    ({
      yes: "Да",
      no: "Нет",
      Да: "yes",
      Нет: "no",
    }[key]),
  co_borrower: (key: string) =>
    ({
      yes: "Да",
      no: "Нет",
      Да: "yes",
      Нет: "no",
    }[key]),
  has_collateral: (key: string) =>
    ({
      yes: "Да",
      no: "Нет",
      Да: "yes",
      Нет: "no",
    }[key]),
  has_transport: (key: string) =>
    ({
      rent: "Аренда",
      ownership: "Владение",
      on_credit: "В кредит",
      other: "Другое",
      no_transport: "Нет транспорта",
      Аренда: "rent",
      Владение: "ownership",
      "В кредит": "on_credit",
      Другое: "other",
      "Нет транспорта": "no_transport",
    }[key]),
  has_house: (key: string) =>
    ({
      rent: "Аренда",
      ownership: "Владение",
      on_credit: "В кредит",
      other: "Другое",
      no_house: "Нет жилья",
      Аренда: "rent",
      Владение: "ownership",
      "В кредит": "on_credit",
      Другое: "other",
      "Нет жилья": "no_house",
    }[key]),
  work_place: (key: string) =>
    ({
      government_agency: "Государственное учреждение",
      private_company: "Частная компания",
      public_organization: "Общественная организация",
      individual_entrepreneur: "Индивидуальный предприниматель",
      other: "Другое",
      none: "Нет",
      "Государственное учреждение": "government_agency",
      "Частная компания": "private_company",
      "Общественная организация": "public_organization",
      "Индивидуальный предприниматель": "individual_entrepreneur",
      Другое: "other",
      Нет: "none",
    }[key]),
  bkit_score: (key: string) =>
    ({
      A: "A",
      B: "B",
      C: "C",
      D: "D",
      E: "E",
      F: "F",
      G: "G",
      H: "H",
      I: "I",
      J: "J",
      M: "M",
      N: "N",
      O: "O",
      P: "P",
      Q: "Q",
      R: "R",
      S: "S",
      T: "T",
      U: "U",
      V: "V",
      W: "W",
      X: "X",
      Y: "Y",
      Z: "Z",
      none: "Нет оценки",
      "Нет оценки": "none",
    }[key]),
  marital_status: (key: string) =>
    ({
      married: "Женат/Замужем",
      divorced: "Разведён/Разведена",
      single: "Холостой",
      widow_widower: "Вдовец/Вдова",
      "Женат/Замужем": "married",
      "Разведён/Разведена": "divorced",
      Холостой: "single",
      "Вдовец/Вдова": "widow_widower",
    }[key]),
  currency: (key: string) =>
    ({
      tjs: "TJS",
      usd: "USD",
      rub: "RUB",
      TJS: "tjs",
      USD: "usd",
      RUB: "rub",
    }[key]),
  district: (key: string) =>
    ({
      mastchoh: "Матчинский район", //
      hissor: "Гиссарский район", //
      dushanbe: "Душанбе", //
      b_gafurov: "Гафуровский район", //
      rudaki: "Рудаки", //
      j_balkhi: "Балхи", //
      j_rasulov: "Джаббар-Расуловский район", //
      khujand: "Худжанд", //
      shahrituz: "Шахритуз", //
      isfara: "Исфара", //
      kulob: "Куляб", //
      istaravshan: "Истаравшан", //
      vahdat: "Вахдат", //
      spitamen: "Спитамен", //
      konibodom: "Конибодом", //?????????????
      kurgonteppa: "Бохтар", //
      zafarobod: "Зафаробод", //
      asht: "Ашт", //
      jayhun: "Джайхун", //
      shahriston: "Шахристон", //
      yovon: "Ёвон", //
      dangara: "Дангара", //
      panjakent: "Пенджикент", //
      tursunzoda: "Турсунзода", //
      devashtich: "Деваштич", //
      kushoniyon: "Кушониён", //??????????
      muminobod: "Муминобод", //
      hamadoni: "Хамадони", //
      vose: "Восеъ", //
      somon: "Сомон", //
      farkhor: "Фархор", //
      dusti: "Дусти", //
      temurmalik: "Темурмалик", //
      vakhsh: "Вахш", //
      panj: "Пяндж", //
      khuroson: "Хуросон", //
      shahrinav: "Шахринав", //
      a_jomi: "Джоми", //
      sangtuda: "Сангтуда", //
      kangurt: "Кангурт", //
      nosiri_khusrav: "Носири Хусрав", //
      faizabad: "Файзобод", //
      farovon: "Фаровон", //
      chirik: "Чирик", //
      guliston: "Гулистон", //??????????????????????
      buston: "Бустон", //
      levakant: "Леваканд (Сарбанд)", //?????????????
      khorug: "Хорог", //???????????????
      tabashar: "Табошар", //?????????????
      norak: "Нурек", //
      firdavsi: "Фирдавси", //??????????????
      gubodiyon: "Кубодян", //
      eshbek_satorov: "Эшбек Саторов",
      korvon: "Корвон",
      "Матчинский район": "mastchoh",
      "Гиссарский район": "hissor",
      Душанбе: "dushanbe",
      "Гафуровский район": "b_gafurov",
      Рудаки: "rudaki",
      Балхи: "j_balkhi",
      "Джаббар-Расуловский район": "j_rasulov",
      Худжанд: "khujand",
      Шахритуз: "shahrituz",
      Исфара: "isfara",
      Куляб: "kulob",
      Истаравшан: "istaravshan",
      Вахдат: "vahdat",
      Спитамен: "spitamen",
      Конибодом: "konibodom",
      Бохтар: "kurgonteppa",
      Зафаробод: "zafarobod",
      Ашт: "asht",
      Джайхун: "jayhun",
      Шахристон: "shahriston",
      Ёвон: "yovon",
      Дангара: "dangara",
      Пенджикент: "panjakent",
      Турсунзода: "tursunzoda",
      Деваштич: "devashtich",
      Кушониён: "kushoniyon",
      Муминобод: "muminobod",
      Хамадони: "hamadoni",
      Восеъ: "vose",
      Сомон: "somon",
      Фархор: "farkhor",
      Дусти: "dusti",
      Темурмалик: "temurmalik",
      Вахш: "vakhsh",
      Пяндж: "panj",
      Хуросон: "khuroson",
      Шахринав: "shahrinav",
      Джоми: "a_jomi",
      Сангтуда: "sangtuda",
      Кангурт: "kangurt",
      "Носири Хусрав": "nosiri khusrav",
      Файзобод: "faizabad",
      Фаровон: "farovon",
      Чирик: "chirik",
      Гулистон: "guliston",
      Бустон: "buston",
      "Леваканд (Сарбанд)": "levakant",
      Хорог: "khorug",
      Табошар: "tabashar",
      Нурек: "norak",
      Фирдавси: "firdavsi",
      Кубодян: "gubodiyon",
      "Эшбек Саторов": "eshbek_satorov",
      Корвон: "korvon",
    }[key]),
  gender: (key: string | number) =>
    ({
      male: "Мужской",
      female: "Женский",
      Мужской: "male",
      Женский: "female",
    }[key]),
  userRole: {
    getValueFromKey: (text: string) => {
      switch (text) {
        case "expert":
          return "Кредитный эксперт";
        case "manager":
          return "Администратор";
        case "root":
          return "Root";
        default:
          return "Кредитный эксперт";
      }
    },
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "Кредитный эксперт":
          return "expert";
        case "Администратор":
          return "manager";
        case "Root":
          return "root";
        default:
          return "Кредитный эксперт";
      }
    },
  },
};

export default transcript;
